import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/public/img/ensemble.webp");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/public/img/general/logo-light.svg");
;
import(/* webpackMode: "eager", webpackExports: ["showToast"] */ "/var/www/html/veels-frontend/src/app/CustomToastProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/veels-frontend/src/components/blog/Blog3.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/veels-frontend/src/components/common/CallToActions.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/src/components/destinations/PopularDestinations.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/veels-frontend/src/components/header/header-1/index.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/html/veels-frontend/src/components/hero/hero-1/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/veels-frontend/src/components/home/home-1/Testimonial.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/html/veels-frontend/src/components/hotels/Hotels.tsx");
